export const jsonData = {
  code: 200,
  message: 'OK',
  data: {
    total: 15,
    length: 15,
    calls: [
      {
        contact_name: 'Maya',
        date: 1695311729,
        status: 'incoming',
        count: 1,
        duration: '00:08:15',
        phone_number: '555-901-2345',
      },
      {
        contact_name: 'Alex',
        date: 1695216040,
        status: 'outgoing',
        count: 1,
        duration: '00:07:55',
        phone_number: '555-012-3456',
      },
      {
        contact_name: 'Lily',
        date: 1695155455,
        status: 'incoming',
        count: 1,
        duration: '00:10:05',
        phone_number: '555-321-0987',
      },
      {
        contact_name: 'Lucas',
        date: 1695060644,
        status: 'missed',
        count: 1,
        duration: '--',
        phone_number: '555-890-1234',
      },
      {
        contact_name: 'Noah',
        date: 1694952920,
        status: 'outgoing',
        count: 1,
        duration: '00:05:40',
        phone_number: '555-210-9876',
      },
      {
        contact_name: 'Mia',
        date: 1694881529,
        status: 'incoming',
        count: 1,
        duration: '00:11:20',
        phone_number: '555-789-0123',
      },
      {
        contact_name: 'Sophia',
        date: 1694814014,
        status: 'outgoing',
        count: 1,
        duration: '00:09:30',
        phone_number: '555-654-3210',
      },
      {
        contact_name: 'Jackson',
        date: 1694698210,
        status: 'missed',
        duration: '--',
        count: 1,
        phone_number: '555-456-7890',
      },
      {
        contact_name: 'Ava',
        date: 1694625325,
        status: 'outgoing',
        count: 1,
        duration: '00:08:50',
        phone_number: '555-765-4321',
      },
      {
        contact_name: 'Liam',
        date: 1694530240,
        status: 'incoming',
        count: 1,
        duration: '00:06:15',
        phone_number: '555-345-6789',
      },
      {
        contact_name: 'Olivia',
        date: 1694470254,
        status: 'outgoing',
        count: 1,
        duration: '00:12:05',
        phone_number: '555-876-5432',
      },
      {
        contact_name: 'Ethan',
        date: 1694370029,
        status: 'incoming',
        count: 1,
        duration: '00:07:40',
        phone_number: '555-234-5678',
      },
      {
        contact_name: 'Emily',
        date: 1694267114,
        status: 'missed',
        duration: '--',
        count: 1,
        phone_number: '555-567-8901',
      },
      {
        contact_name: 'Michael',
        date: 1694203520,
        status: 'outgoing',
        count: 1,
        duration: '00:15:10',
        phone_number: '555-987-6543',
      },
      {
        contact_name: 'Sarah',
        date: 1694100645,
        status: 'incoming',
        count: 1,
        duration: '00:08:25',
        phone_number: '555-123-4567',
      },
    ],
    update_time: 1697596042,
  },
};
